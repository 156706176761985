












































import { Vue, Component, Ref, Mixins } from 'vue-property-decorator';

import moment from "moment";

import VModal from '@/components/VModal.vue';
import ExportsMixin, { ExportsMixinTags } from '@/mixins/http/ExportsMixin';
import withErrors from '@/directives/WithErrors';
import { ExportPayload } from '@/models/Export';
import { easync } from '@/utils/http';
import { showErrorToast, showToast } from '@/utils/Toast';
import { sourceStore } from '@/store/typed';
import OrderLinesExportCable from '@/cables/OrderLinesExportCable';

@Component({
  components: {
    VModal
  },
  directives: {
    withErrors
  }
})
export default class ExportModal extends Mixins(ExportsMixin) {
  private payload: ExportPayload = { name: '', sourceId: '' };

  private cable: OrderLinesExportCable | null = null;
  private jobProgress = 0;
  private exporting = false;

  @Ref() modal!: VModal;

  private connectToCable() {
    this.cable = new OrderLinesExportCable();
    this.cable.onResponse = this.onCableResponse;
    this.cable.open();
  }

  show() {
    this.prefillName();
    this.connectToCable();
    this.modal.show();
  }

  hide() {
    this.jobProgress = 0;
    this.payload.name = '';
    this.cable.close();
    this.exporting = false;
    this.modal.hide();
  }

  get createTag() {
    return ExportsMixinTags.Create;
  }

  get style() {
    return `width: ${this.jobProgress}%`;
  }

  private prefillName() {
    this.payload.name = `export_${moment().format('DD_MM_YYYY')}`;
  }

  private async startExport() {
    this.payload.sourceId = sourceStore.currentSource.id;
    const [data, errors] = await easync(this.createExport(this.payload, ExportsMixinTags.Create));

    if (errors) {
      showErrorToast("Si è verificato un errore nell'avvio dell'export");
    }
  }

  private onCableResponse(data: any) {
    switch(data.event) {
      case 'export.progress':
        this.exporting = true;
        this.jobProgress = data.progress;
        break;
      case 'export.completed':
        this.exportSuccessfullOps(data);
        break;
      case 'export.failed':
        this.exportFailedOps();
        break;
    }
  }

  private exportSuccessfullOps(data: any) {
    showToast('Esportazione Completata!');
    this.downloadExport(data.progress.result);
    this.$emit('job-finished');
    this.$nextTick(() => this.hide());
  }

  private exportFailedOps() {
    showErrorToast('Esportazione fallita');
    this.$emit('job-finished');
    this.hide();
  }

  private downloadExport(url: string) {
    const element = document.createElement('a');
    
    element.setAttribute('href', url);
    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
