import { Subscription } from "@rails/actioncable";
import store from "@/store";

class ExportCycleAdapter {
  received: (data: any) => void;

  constructor(onResponse: (data: any) => void) {
    this.received = onResponse;
  }
}

export default class OrderLinesExportCable {
  subscription: Subscription & ExportCycleAdapter | null = null;

  onResponse!: (data: any) => void;

  close() {
    store.commit('disconnect', {root: true});
  }

  async open() {
    const params = {
      channel: "OrderLinesExportChannel",
    };
    const handler = new ExportCycleAdapter(this.onResponse);

    this.subscription = await store.dispatch('subscribe', {params, handler});
    if (this.subscription) {
      console.log('Connected to channel: OrderLinesExportChannel')
    }
  }
}
