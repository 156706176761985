import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_EXPORTS_URL = `${BASE_API_URL}exports`;

export enum ExportsMixinTags {
  Index = 'ExportsIndex',
  Create = 'ExportCreate',
}

@Component
export default class ExportsMixin extends Vue {

  getExports(data: any, tag: string) {
    return this.$httpGetQuery(BASE_EXPORTS_URL, tag, data);
  }

  createExport(data: any, tag: string) {
    return this.$httpPost(BASE_EXPORTS_URL, tag, data);
  }
}
