import { Source } from ".";
import Model from "./Model";

export interface ExportPayload {
  name: string;
  sourceId: string;
}

export default class Export extends Model {
  static type = "exports";

  name: string;
  url: string;
  status: string;
  source: Source;
}

export const STATUS_CLASSES: Record<string, string> = {
  'processing': 'badge badge-warning',
  'completed': 'badge badge-success',
  'export_error': 'badge badge-danger',
}
