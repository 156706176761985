









































































import { Component, Mixins, Ref } from 'vue-property-decorator';

import ExportsMixin, { ExportsMixinTags } from '@/mixins/http/ExportsMixin';
import Export, { STATUS_CLASSES } from '@/models/Export';
import { easync } from '@/utils/http';

import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import VPaginator from '@/components/VPaginator.vue';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import ExportOrderLinesCable from '@/cables/OrderLinesExportCable';
import ExportModal from '@/components/Export/Modal.vue';

@Component({
  components: {
    DataTable,
    VPaginator,
    ExportModal
  }
})
export default class ExportList extends Mixins(ExportsMixin) {
  private exports: Export[] = [];

  @RegisterHttp(ExportsMixinTags.Index) readonly indexRequest!: RequestData;

  @Ref() exportModal!: ExportModal;

  created() {
    this.loadExports();
  }

  get loading() {
    return this.indexRequest?.loading;
  }

  get meta() {
    return this.indexRequest?.meta;
  }

  get columns() {
    return [
      { name: 'name', label: 'Nome' },
      { name: 'status', label: 'Stato' },
      { name: 'url', label: 'File generato' },
    ]
  }

  private statusClass(status: string) {
    return STATUS_CLASSES[status];
  }

  private async loadExports(page = 1) {
    const filter = {
      page: page
    }
    const [data, errors] = await easync(this.getExports(filter, ExportsMixinTags.Index));

    if (data) {
      this.exports = data;
    } else {
      this.exports = [];
    }
  }

  private openExportModal() {
    this.exportModal.show();
  }
}
